import React from "react";
import Slider from "react-slick";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  centerMode: true,
  centerPadding: "15%",
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        centerMode: false,
        centerPadding: "0%",
      },
    },
  ],
};

const InterventoSlider = ({ items }) => {
  return (
    <>
      {items?.nodes.length > 0 && (
        <section className="section">
          <div className="box-intervento">
            <div
              className="section__bg-title section__bg-title--small text-center text-uppercase"
              aria-hidden="true"
            >
              The talk
            </div>

            <div className="box-intervento__title-section text-uppercase">The talk</div>

            <Slider {...settings} className="slider-intervento">
              {items?.nodes.map((item, i) => (
                <div key={i}>
                  <div className="slider-intervento__slide d-flex flex-column flex-md-row">
                    <div className="box-intervento__info">
                      {item.numberDay && (
                        <div className="box-intervento__date text-center">
                          <span className="day">{item.numberDay}</span>
                          <span className="month text-uppercase">{item.month}</span>
                        </div>
                      )}
                      {item.time && (
                        <div className="box-intervento__orario text-center text-md-start">
                          {item.time}
                        </div>
                      )}
                      {item.room && (
                        <div className="box-intervento__luogo text-center text-md-start">
                          {item.room}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="box-intervento__title text-uppercase">{item.eventTitle}</div>
                      {item.eventDescription?.childMarkdownRemark?.html && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.eventDescription?.childMarkdownRemark?.html,
                          }}
                          className="box-intervento__text"
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
};

export default InterventoSlider;
