import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import InterventoSlider from "../components/slider-intervento";
import Wall from "../components/box-wall";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

import LinkedIn from "../icons/linkedin-in.svg";
import WebSite from "../icons/browser-solid.svg";

const Spline = lazy(() => import("@splinetool/react-spline"));

const SpeakerDetail = ({ data }) => {
  const { speaker, events, speakers } = data;

  return (
    <Layout>
      <Seo title={`${speaker.fullName}`} description={`${speaker.bio?.bio?.substring(0, 160)}`} />

      <div className="speaker-detail">
        <div>
          <div className="speaker-detail__info text-end">
            <div className="container-md">
              <div className="row">
                <div className="col-12 col-md-9 col-xl-8">
                  <div className="speaker-detail__name text-uppercase">
                    <span className="text-nowrap">{speaker.name}</span> {speaker.surname}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-9 col-xl-8">
                  {speaker.role && (
                    <div className="speaker-detail__role text-uppercase">{speaker.role}</div>
                  )}
                  <div className="speaker-detail__social d-flex justify-content-end">
                    {speaker.linkedin && (
                      <a
                        href={speaker.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Visualliza la pagina su LinkedIn"
                        className="speaker-detail__linkedin"
                      >
                        <LinkedIn className="ico-svg" />
                      </a>
                    )}
                    {speaker.website && (
                      <a
                        href={speaker.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Visualliza il sito internet"
                        className="speaker-detail__website ms-3"
                      >
                        <WebSite className="ico-svg" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-md-4 offset-md-8 col-xl-5 offset-xl-7">
                <GatsbyImage
                  image={getImage(speaker.image)}
                  alt={speaker.fullName}
                  className="speaker-detail__img"
                  loading="eager"
                />
              </div>
            </div>
          </div>
          <div className="section section--grey-dark speaker-detail__bio">
            <Suspense>
              <Spline
                scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
                className="animation-circle"
              />
            </Suspense>
            <div className="box-article">
              <div className="container-md">
                <div className="row">
                  <div className="col-12 col-xl-7 offset-xl-1">
                    <h2 className="box-article__title">{speaker.role}</h2>
                    {speaker.bio?.childMarkdownRemark?.html && (
                      <div
                        dangerouslySetInnerHTML={{ __html: speaker.bio?.childMarkdownRemark?.html }}
                        className="box-article__text"
                      ></div>
                    )}
                  </div>
                </div>
                {speaker.publications && speaker.publications.length && (
                  <div className="row">
                    <div className="col-12 col-xl-4 offset-xl-8">
                      <div className="speaker-detail__pubblicazioni">
                        <div className="text-end text-uppercase">Pubblicazioni</div>
                        <ul className="text-end">
                          {speaker.publications.map((child, j) => (
                            <li key={j}>
                              <a
                                href={child.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={child.name}
                              >
                                {child.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <InterventoSlider items={events} />
      </div>

      <Wall items={speakers} title={true} />

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default SpeakerDetail;

export const query = graphql`
  query ($contentful_id: String) {
    speaker: contentfulSpeaker(contentful_id: { eq: $contentful_id }) {
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      name
      surname
      fullName
      role
      program
      link
      linkedin
      bio {
        childMarkdownRemark {
          html
        }
      }
      publications {
        name
        link
      }
    }
    speakers: contentfulSpeakerList {
      speakers {
        fullName
        link
      }
    }
    events: allContentfulEvent(
      filter: { speaker: { elemMatch: { contentful_id: { eq: $contentful_id } } } }
      sort: { fields: day }
    ) {
      nodes {
        eventTitle
        month
        numberDay
        room
        time
        eventDescription {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
